//
// Header styles
//
// 1. Site header
// 2. Site alert
//


//
// Site header
//
.site-header {
  padding-top: 110px;

  .branding {
    position: relative;
    padding-top: 15px;
    padding-left: $outer-gutter-sm;

    a {
      display: inline-block;
      line-height: 0;
      z-index: 99;
    }

    .logo-outer, .logo-inner {
      display: none;
    }

    .logo-mobile {}
  }

  @include media-breakpoint-down(sm) {
    padding-top: 145px
  }

  @include media-breakpoint-up(lg) {
    position: static;
    padding: 0;

    .branding {
      display: block;
      position: fixed;
      top: 30px;
      left: calc(#{$rail-width-lg} - 97px);
      width: 194px;
      height: 194px;
      z-index: 100;
      transition: all 0.6s ease;
      padding: 0;

      a {
        display: block;
      }

      .logo-mobile {
        display: none;
        border: 5px solid green;
      }

      .logo-outer {
        display: block;
        transform-origin: 50% 50%;
        width: 194px;
        height: 194px;
        transition: all 1.5s ease;
        z-index: 1;
        position: relative;
      }

      .logo-inner {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-53%, -51%, 0);
        width: 118px;
        height: 118px;
        z-index: 2;

      }

      // effects

      body.is-loading &,
      html:not(.is-scrolling) > body.is-loading.home &,
      body.is-unloading &,
      &.is-branding-offscreen-up {
        top: calc(0% - 200px);

        .logo-outer {
          transform: rotate(-360deg);
        }
      }

      html:not(.is-scrolling) > body.home.is-loaded &,
      &.is-branding-centered {
        top: calc(50% - 97px);

        .logo-outer {
          transform: rotate(360deg);
        }
      }

      html:not(.is-scrolling) > body.home.is-unloading &,
      &.is-branding-offscreen-down {
        top: calc(100% + 97px);

        .logo-outer {
          transform: rotate(360deg);
        }
      }

      .is-footer-scrolling > body:not(.beer) & {
        opacity: 0;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .branding {
      left: calc(#{$rail-width-xl} - 97px);
    }
  }

  .nav-main {
    background: $black;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;

    @include media-breakpoint-up(lg) {
      position: fixed;
      min-height: 183px;
      width: $rail-width-lg;
    }

    @include media-breakpoint-up(xl) {
      width: $rail-width-xl;
     }

    ul.site-menu {
      padding: 39px 0 50px 98px;
      position: absolute;
      background: $black;
      width: 100%;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.6s ease;
      transform: translateY(-100%);

      @include media-breakpoint-up(lg) {
        padding: 74px 0 64px 130px;
      }

      @include media-breakpoint-up(xl) {
        padding: 95px 0 64px 152px;
      }

      .is-menu-active &,
      .is-menu-hovering & {
        transform: translateY(0);
        opacity: 1;
      }
    }

    li {
      margin-bottom: 2px;
    }

    a {
      font-family: $font-family-display;
      font-weight: $font-weight-normal;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
    }
  }

  .nav-toggle {
    position: absolute;
    left: $outer-gutter-sm;
    top: $outer-gutter-sm;
    @include button-reset();
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;

    @include media-breakpoint-up(lg) {
      left: $outer-gutter-lg;
      top: $outer-gutter-lg;
    }

    @include media-breakpoint-up(xl) {
      left: $outer-gutter-xl;
      top: $outer-gutter-xl - 1px;
    }

    .bar,
    .bar::before,
    .bar::after {
      box-sizing: content-box;
      display: block;
      width: 33px;
      height: 3px;
      border: 1px solid $black;
      background: $white;
      position: relative;
    }

    .bar::before,
    .bar::after {
      content: '';
      left: -1px;
      transform-origin: 50% 50%;
      position: absolute;
      transition: transform 0.2s;
    }
    .bar::before {
      transform: translateY(12px);
    }
    .bar::after {
      transform: translateY(-14px);
    }

    .is-menu-active &,
    .is-menu-hovering & {
      .bar {
        height: 0;
        border: 0;
      }
      .bar::before {
        transform: rotate(-45deg) translateY(0);
      }
      .bar::after {
        transform: rotate(45deg) translateY(0);
      }
    }
  }

  .nav-utility {
    position: absolute;
    top: $outer-gutter-sm - 6px;
    right: $outer-gutter-sm;
    z-index: 98;

    .btn,
    .TockButton {
      color: $white !important;
      border-color: $white !important;
      border-width: 2px !important;
      background: transparent !important;
      text-transform: uppercase;

      &:hover,
      &:focus {
        color: $red !important;
        border-color: $red !important;
      }
    }

    .btn {
      @include media-breakpoint-down(sm) {
        display: inline-block;
        clear: both;
        float: right;
        margin-right: 10px;
      }
    }

    #Tock_widget_container {
      float: left;
      display: inline-block;
      .TockLogo-container {
        display: none !important;
      }
      margin-right: 10px;

      .TockWidgetWrapper .TockWidget-H1 {
        visibility: hidden;
        display: none;
      }

      .TockWidgetWrapper .TockButton:after {
        content: 'Reserve a Table';
        font-family: $font-family-display !important;
        letter-spacing: .1em;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        float: right;
        margin-bottom: 10px;
      }
    }

    @include media-breakpoint-up(lg) {
      position: fixed;
      top: $outer-gutter-lg;
      right: $outer-gutter-lg;
    }

    @include media-breakpoint-up(xl) {
      top: $outer-gutter-xl - 11px;
      right: $outer-gutter-xl + 2px;
    }

    .is-footer-scrolling & {
      opacity: 0;
    }
  }

}

//
// Site alert
//
.site-alert {
  padding: 0 $outer-gutter-sm;
  transition: opacity 600ms ease;

  .is-scrolling & {
    opacity: 0;
  }

  .alert-container {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 600ms ease, opacity 300ms;

    .is-alert-active &,
    .is-alert-hovering & {
      max-height: 2000px;
      opacity: 1;
    }
  }

  .alert-body {
    padding-bottom: 60px;
  }

  .alert-toggle {
    @include button-reset();
    color: $red;
    position: absolute;
    top: $outer-gutter-sm + 2px;
    left: $outer-gutter-sm + 56px;
    cursor: pointer;
    display: block;
    width: 35px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.376' height='39.376'%3E%3Cg transform='translate(-1574.841 533.108)' fill='%23e1001e'%3E%3Cpath data-name='Path 42' d='M1594.529-493.732a19.688 19.688 0 0 1-19.688-19.688 19.688 19.688 0 0 1 19.688-19.688 19.688 19.688 0 0 1 19.688 19.688 19.688 19.688 0 0 1-19.688 19.688zm0-36.563a16.876 16.876 0 0 0-16.875 16.875 16.875 16.875 0 0 0 16.875 16.875 16.874 16.874 0 0 0 16.871-16.875 16.875 16.875 0 0 0-16.871-16.88z'/%3E%3Cpath data-name='Path 43' d='M1594.528-504.111a16.218 16.218 0 0 1-7.7-1.94 1.355 1.355 0 0 1-.552-1.794 1.266 1.266 0 0 1 1.733-.571 13.539 13.539 0 0 0 6.521 1.648 14 14 0 0 0 11.81-6.644 13.833 13.833 0 0 0-19.551-4.212 14.382 14.382 0 0 0-4.069 4.212 14.5 14.5 0 0 0 2.426 2.923 1.364 1.364 0 0 1 .1 1.88 1.255 1.255 0 0 1-1.816.1 17.173 17.173 0 0 1-3.325-4.239 1.374 1.374 0 0 1 0-1.329 16.382 16.382 0 0 1 22.814-6.256 16.993 16.993 0 0 1 6.044 6.256 1.367 1.367 0 0 1 0 1.329 16.591 16.591 0 0 1-14.435 8.637z'/%3E%3Cpath data-name='Path 44' d='M1594.529-508.42a5 5 0 0 1-5-5 5 5 0 0 1 5-5 5 5 0 0 1 5 5 5 5 0 0 1-5 5zm0-8a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 35px 35px;

    .is-alert-active &,
    .is-alert-hovering & {
      background-image: url("data:image/svg+xml,%3Csvg height='39.376' viewBox='0 0 39.376 39.376' width='39.376' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-1574.841 533.108)'%3E%3Cpath d='m1594.529-493.732a19.688 19.688 0 0 1 -19.688-19.688 19.688 19.688 0 0 1 19.688-19.688 19.688 19.688 0 0 1 19.688 19.688 19.688 19.688 0 0 1 -19.688 19.688zm0-36.563a16.876 16.876 0 0 0 -16.875 16.875 16.875 16.875 0 0 0 16.875 16.875 16.874 16.874 0 0 0 16.871-16.875 16.875 16.875 0 0 0 -16.871-16.88z' fill='%23e1001e'/%3E%3Cpath d='m0 0h21' fill='none' stroke='%23e1001e' stroke-linecap='round' stroke-width='3' transform='translate(1583.751 -513.642)'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 0;
    left: $outer-gutter-lg + 56px;
    padding: 0;
    display: flex;
    background: none;
    padding-right: 60px;
    align-items: center;
    height: 165px;
    z-index: 101; // above .nav-main (99) and logo (100)

    .is-menu-active &,
    .is-menu-hovering & {
      z-index: 99; // below .nav-main (99)
    }

    .is-alert-active &,
    .is-alert-hovering & {
      background: $black;

      // hacky way to block content below hamburger menu
      &::after {
        content: "";
        width: 100%;
        height: 70px;
        background: $black;
        position: absolute;
        bottom: 0;
        left: -50%;
        z-index: -1;
      }

      .alert-container {
        max-width: 800px;
        opacity: 1;
      }
    }

    .alert-container {
      max-height: 175px;
      height: 175px;
      transition: max-width 600ms, opacity 300ms;
      max-width: 0px;
      overflow: hidden;
      opacity: 0;
      display: flex;
      align-items: center;
    }

    .alert-body {
      flex-shrink: 0;
      width: 560px;
      padding: 0 20px 0 40px;
    }

    .alert-toggle {
      position: relative;
      top: auto;
      left: auto;
    }
  }

  @include media-breakpoint-up(xl) {
    left: $outer-gutter-xl + 56px;
    height: 212px;
  }
}
