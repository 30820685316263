//
// Page styles
//
// 1. Page intro and hero mobile overrides
// 2. Page sections
// 3. Page container (Below viewport or intro/hero)
// 4. In-page links (Taplist, Contact, Top, etc.)
// 5. Page subnavigation
// 6. Location info (Contact, Hours, Buy)
//


//
// Page intro and hero  - vertical on mobile
//
@include media-breakpoint-down(lg) {
  .masthead-container {
    display: flex;
    flex-direction: column;

    .section--news {
      flex: 0 1 auto;
      width: 100%;
    }

    .hero--main {
      height: 1px;
      min-height: 412px;
      flex: 1;
    }
  }
}

//
// Side rail sections
//
.section {
  padding: 61px $outer-gutter-sm;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;
  background: $black;



  @include media-breakpoint-up(lg) {
    padding: 90px $outer-gutter-lg;
    width: $rail-width-lg;
  }

  @include media-breakpoint-up(xl) {
    padding: 90px $outer-gutter-xl;
    width: $rail-width-xl;
  }
}

.section--news {
  padding-top: 35px;
  padding-bottom: 18px;

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
    background: transparent;
  }
}

.section--home {
  @include media-breakpoint-down(lg) {
    position: relative;

    .section-inner {
      position: relative;
      z-index: 2;
    }

    .hero {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
      z-index: 1;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 80px;
  }
}

.section--feature {
   @include media-breakpoint-up(lg) {
    min-height: 100vh;
  }
}

.section--locations {
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
}

.section--top {
  @include media-breakpoint-up(lg) {
    padding-top: 300px;
  }
}

//
// Page container (below the viewport/hero)
//
.page-container {
  position: relative;
  z-index: 1;
}

// Page links (E, W, SE, SW)
.page-up,
.page-down,
.page-link {
  font-family: $font-family-display;
  font-size: 26px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 10px 10px 40px;

  &:hover, &:active {
    text-decoration: none;
  }

  @include media-breakpoint-down(lg) {
    &.d-none {
      display: none;
    }
  }
}

.page-down::after,
.page-up::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 16px solid $white;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  position: absolute;
  bottom: 0;
  left: 10px;
  transition: all 200ms ease;
}

.page-down--right::after {
  left: auto;
  right: 10px;
}

.page-down {
  position: fixed;
  bottom: 20px;
  left: ($outer-gutter-sm - 5px);
  z-index: 1;
  min-width: 45px;

  @media (max-height: 648px) {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    span {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    bottom: 56px;
    left: ($outer-gutter-lg - 10px);

    &:hover {
      &::after {
        transform: translateY(100%);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    //bottom: 90px;
    left: ($outer-gutter-xl - 10px);
  }

  @media (min-width: $lg) and (max-height: 640px) {
    display: none;
  }
}

.page-down--right {
  left: auto;
  right: 104px;
  z-index: 2;
}

.page-up {
  display: block;
  position: relative;
  text-align: center;
  z-index: 99;
  padding: 40px 10px 10px 10px;

  margin: 10px auto 30px;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 35px;
    right: 70px;
    bottom: auto;
    padding: 35px 10px 10px 10px;

    &.is-fixed {
      position: fixed;
    }
  }

  @include media-breakpoint-up(xl) {
    top: 62px;
    right: 104px;
  }
}

.page-up::after {
  border-top: 0;
  border-bottom: 16px solid $white;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 200ms ease;
}

.page-link,
.page-link--left {
  position: fixed;
  top: 50%;
  left: 42px;
  transform: translateY(-50%);
  padding: 10px 10px 10px 43px;
}

.page-link::after,
.page-link--left::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-right: 16px solid $white;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  position: absolute;
  top: calc(50% - 8px);
  left: 0;
  transition: all 200ms ease;
}

.page-link--right::after {
  border-right: 0;
  border-left: 16px solid $white;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  left: auto;
  right: 0;
}

.page-link--right {
  left: auto;
  padding: 10px 43px 10px 10px;
  right: 40px;
}

@include media-breakpoint-up(lg) {
  .page-link--left:hover {
    &::after {
      transform: translateX(-100%);
    }
  }

  .page-link--right:hover {
    &::after {
      transform: translateX(100%);
    }
  }
}

//
// Page sub-navigation
//
.page-nav {
  position: relative;
  padding: 15px ($outer-gutter-sm - 5px) 0;
  background: $black;
  z-index: 1;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li:not(:last-child) {
    margin-right: 15px;
  }

  @include media-breakpoint-down(lg) {
    position: sticky;
    top: 0;
    left: 0;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: $rail-width-lg;
    top: 0;
    height: 100vh;
    padding: 0;
    background: none;

    &.is-fixed {
      position: fixed;
    }

    ul {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: $black;
      padding: 19px 20px 15px;
      min-width: 5px;
    }

    li {
      margin: 0;
      line-height: 1.4;
    }
  }

  @include media-breakpoint-up(xl) {
    left: $rail-width-xl;
  }

  a {
    display: block;
    padding: 5px;
    font-family: $font-family-display;
    text-transform: uppercase;
    font-size: 15px;
    color: $gray;
    text-decoration: none;
    letter-spacing: 0.12em;

    white-space: nowrap;

    &:hover, &:active {
      color: $white;
      text-decoration: none;
    }
  }
}

//
// Location info
//
.page-info {
  background: black;
  font-family: $font-family-display;
  font-size: rem(15);
  letter-spacing: 0.01em;
  white-space: nowrap;
  width: 100%;
  padding: 0 $outer-gutter-sm;

  @include media-breakpoint-up(lg) {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 50px 62px 50px;
    width: calc(#{$main-width-lg} - 120px);
    max-width: 880px;
  }

  p {
    margin: 0;
    line-height: 1.6;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .grid__col {
    margin-top: 42px;
  }

  @include media-breakpoint-up(lg) {
    .grid__col:not(.social) {
      margin-top: 0;
    }
    .grid__col.hours {
      padding-left: 20px;
    }

  }

  @include media-breakpoint-up(xl) {
    .grid__col.hours {
      padding-left: 41px;
    }
    .grid__col.social {
      margin-top: 0;
      padding-left: 31px;
    }
  }
}
