//
// Beers page
//

.beer-container {
  //
  // Page layout
  //
  display: flex;
  flex-direction: column;

  background-repeat: no-repeat;
  background-position: right top;

  @media (min-height: 800px) {
    background-size: auto 100%;
  }

  @include media-breakpoint-down(lg) {
    background-image: none !important;
  }

  @extend %animated;
  animation-name: slideInLeft;

  &.animate-out {
    animation-name: slideOutRight;
  }

  .beer-header {
    padding: 40px $outer-gutter-sm 15px;

    @include media-breakpoint-up(lg) {
      padding: 40px $outer-gutter-lg;
    }

    @include media-breakpoint-up(xl) {
      padding: 40px $outer-gutter-xl;
    }
  }

  .beer-carousel {
    flex: 1;
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-down(lg) {
    .swiper-wrapper {
      flex-direction: column;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 250px;
    height: 100vh;

    .beer-header {
      order: 1;
    }

    .beer-carousel {
      order: 0;
    }
  }

  //
  // Heading styles
  //
  .beer-heading {
    opacity: 1;
    transition: all 600ms ease;
    padding: 0 $outer-gutter-sm;
    margin-bottom: 35px;

    @extend %animated;

    &.is-active {
      animation-name: fadeIn;
    }

    &.d-none {
      // Toggled in view by the carousel.
      // Header duplicates within the carousel
      // are the mobile headers in the beer stack.
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 0;

      &.d-none,
      &.d-none-lg {
        display: none;
      }

      p {
        margin-left: 60px;
        max-width: 420px;
      }
    }
  }

  //
  // Beer styles
  //
  .beer {
    width: 100%;
    max-width: 222px;
    margin: 0 auto 74px auto;
    padding: 0 $outer-gutter-sm;

    .beer-can {
      text-align: center;
      max-width: 212px;
    }

    .beer-info {
      position: relative;
      margin-top: 30px;

      .beer-title {
        font-size: rem(24);
        border-bottom: 1px solid $gray-light;
        padding-bottom: 12px;
        margin-bottom: 8px;
      }

      .beer-type {
        font-size: rem(16);
        padding-bottom: 10px;
        margin-bottom: 9px;
        border-bottom: 1px solid $gray-light;
        line-height: 1.2;
      }

      .beer-abv {

        padding-bottom: 10px;
        margin-bottom: 7px;
        border-bottom: 1px solid $gray-light;
      }

      .beer-ibu {
        font-size: rem(16);
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      .beer-title {
        padding-bottom: 12px;
        margin-bottom: 14px;
      }

      .beer-type {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      .beer-abv {
        padding-bottom: 20px;
        margin-bottom: 10px;
      }
    }
  }

  //
  // Carousel styles
  //
  .swiper-container {
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .swiper-slide {
      width: 380px;
      height: 400px;
      box-sizing: border-box;

      .beer {
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0;
        position: relative;
      }
    }

    .swiper-slide.last {
      width: 760px;
      padding-right: 380px;
    }

    .swiper-slide.end {
      width: 1380px;
      padding-right: 1000px;
    }

    .beer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0;
      padding: 0;
      opacity: 0.4;
      transition: transform 400ms ease, opacity 200ms ease;

      .beer-can {
        transition: transform 400ms ease;
        align-self: center;
        position: relative;
        z-index: 2;
        width: 212px;
      }

      .beer-info {
        position: absolute;
        top: 0;
        right: -40px;
        z-index: 1;
        width: 215px;
        padding-top: 70px;
        transition: opacity 600ms ease;
        opacity: 0;
      }
    }

    .swiper-slide.is-active:not(.swiper-slide-prev) .beer.is-hovering,
    .swiper-slide.swiper-slide-active .beer.is-hovering {
      .beer-can {
        transform: translateX(-60%);
      }

      .beer-info {
        opacity: 1;
      }
    }

    .swiper-slide-active .beer,
    .swiper-slide.is-active .beer {
      opacity: 1;
    }

    .swiper-slide.swiper-slide-prev.is-active .beer,
    .swiper-slide.is-inactive .beer {
      opacity: 0.4;
    }

    .swiper-button-next {
      right: 30px;
    }

    .swiper-button-prev {
      left: 30px;
    }

    .swiper-button-next::after {
      content: "";
      width: 0;
      height: 0;
      font-family: sans-serif;
      border-right: 0;
      border-left: 19px solid $white;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
    }

    .swiper-button-prev::after {
      content: "";
      width: 0;
      height: 0;
      font-family: sans-serif;
      border-left: 0;
      border-right: 19px solid $white;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
    }

    .swiper-button-next[aria-disabled="true"],
    .swiper-button-prev[aria-disabled="true"] {
      opacity: 0.3;
      cursor: default;
    }

  }

  //
  // Navigation
  //
  .beer-nav {
    display: none;

    @include media-breakpoint-down(lg) {
      &--mobile {
        display: block;
        position: sticky;
        top: 0;
        left: 0;
        padding: 40px $outer-gutter-sm 0;
        background: $black;
        z-index: 2;
      }

      & + .beer-header {
        padding-top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      display: block;

      &--mobile {
        display: none;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 13px;
      margin-left: -5px;
    }

    li:not(:last-child) {
      margin-right: 10px;

      @include media-breakpoint-up(lg) {
        margin-right: 15px;
      }
    }

    a {
      display: block;
      padding: 5px;
      font-family: $font-family-display;
      text-transform: uppercase;
      font-size: rem(15);
      color: $gray;
      text-decoration: none;
      letter-spacing: 0.12em;

      white-space: nowrap;

      &:hover, &:active {
        color: $white;
        text-decoration: none;
      }
    }
  }
}
