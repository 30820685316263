
.site-footer {
  background-repeat: no-repeat;
  background-color: $black;

  background-position: bottom right;
  min-height: 100vh;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 98;

  padding-top: 185px;

  @include media-breakpoint-down(lg) {
    background-image: none !important;
  }

  @include media-breakpoint-up(lg) {
    body.beer & {
      display: none;
    }
  }

  .section {
    background: transparent;
    min-height: 1px;
  }

  .section--footer {
    flex: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 550px;

    @include media-breakpoint-up(lg) {
      padding-top: 30px;
      padding-bottom: 0px;
    }
  }

  .section--locations {
    margin-top: auto;
  }

  .locations {
    font-size: 14px;
    overflow: hidden;
    margin-bottom: 37px;

    .location {
      padding-top: 17px;
      padding-bottom: 17px;
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;

      span:not(:first-child)::before {
        content: "/ ";
      }


      @include media-breakpoint-down(md) {
        &:not(:first-child) {
          border-top: 0;
        }

        &:not(:last-child) {
          border-bottom: 0;
        }
      }
    }

    h2 {
      margin-bottom: 27px;
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 3px;
    }

    a {
      text-decoration: none;
    }

    a[href^="http"] {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .fineprint {
    font-size: rem(13);
    line-height: 2;
    color: $gray;
    margin-bottom: 40px;

    padding-left: $outer-gutter-sm;
    padding-right: $outer-gutter-sm;

    @include media-breakpoint-up(lg) {
      padding-left: $outer-gutter-lg;
      padding-right: $outer-gutter-lg;
    }

    @include media-breakpoint-up(xl) {
      padding-left: $outer-gutter-xl;
      padding-right: $outer-gutter-xl;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
      color: $white;
    }

    p {
      margin-bottom: 0;
    }

    p {
      display: inline;
      padding-right: 1em;

      &:not(:first-child)::before {
        content: "|";
        padding-right: 1.2em;
      }
    }

  }

}
