
@mixin media-breakpoint-up($bp) {
	$value: map-get($grid-breakpoints, $bp); 
	@media (min-width: $value) {
		@content;
	}
}

@mixin media-breakpoint-down($bp) {
	$value: map-get($grid-breakpoints, $bp); 
	@media (max-width: $value - 1px) {
		@content;
	}
}