.btn {
  display: inline-block;
  font-family: $font-family-display;
  font-weight: $font-weight-medium;
  color: $gray;
  text-align: left;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid $gray;
  border-radius: 30px;
  padding: 12px 29px;
  font-size: 16px;
  line-height: 1.5;
  transition: $transition-base;
  text-decoration: none;
  max-width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.10em;

  &:hover,
  &:focus {
    color: $white;
    border-color: $white;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
