
@keyframes splashTimeline {
  0% {
    opacity: 1;
    visibility: visible;
  }

  90% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes splashFadeToBlack {
  from {
    background-color: $white;
  }

  to {
    background-color: $black;
  }
}

@keyframes logoZoomIn {
  0% {
    opacity: 0;
    transform: scale(30);
  }

  80% {
    opacity: 0.8;
    transform: scale(.2);
  }

  85% {
    opacity: 0.85;
    transform: scale(.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50%);
  }
}

@keyframes splashSlideOut {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes morseIn {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  25% {
    transform: translateY(5px);
  }

  35% {

    transform: translateY(-5px);
  }

  45% {
    transform: translateY(2px);
  }

  50% {
    transform: translateY(-2px);
  }

  65% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes morseInUp {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  25% {
    transform: translateY(-5px);
  }

  35% {

    transform: translateY(5px);
  }

  45% {
    transform: translateY(-2px);
  }

  50% {
    transform: translateY(2px);
  }

  65% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

.js {
//
// splash elements positioning and styles
//
.splash {
  position: absolute;
  z-index: 999;
  visibility: visible;
}

.splash-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;

  .splash-morse-code {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 287.3px;
    height: 111px;
    transform: translate3d(-50%, -50%, 0);
    color: $black;
  }

  .splash-logo-stack {
    position: absolute;
    top: calc(50% - 45px);
    left: calc(#{$rail-width-lg} + 75px);

    width: 380px;
    opacity: 0;

    @include media-breakpoint-up(xl) {
      left: calc(#{$rail-width-xl} + 100px);
    }
  }
}

.splash-morse-code svg > * {
  @extend %animated;
  animation-name: morseIn;
  animation-duration: .8s;
  fill: $black;
}

@for $i from 1 through 26 {
  .splash-morse-code svg > #m#{$i} {
    animation-delay: #{($i * 50) + 800}ms;

    @if $i % 2 == 0 {
      animation-name: morseInUp;
    }
  }
}

.home.is-splash-animating {

  //
  // main logo style overrides
  //

  .branding {
    z-index: 9999;
    transform-duration: 1ms;
    opacity: 0;

    a {
      pointer-events: none;
    }

    .logo-outer {
      visibility: hidden;
      opacity: 0;
    }
  }

  .hero--main {
    transform: translateX(100%);
    transition: all 600ms ease;
  }

  //
  // animation timeline
  //

  .splash {
    @extend %animated;
    animation-name: splashTimeline;
    animation-duration: 5s;
    animation-delay: 2s;
  }

  .splash-container {
    @extend %animated;
    animation-name: splashFadeToBlack;
    animation-duration: 0.3s;
    animation-delay: 3.25s;

  }

  .branding {
    @extend %animated;
    animation-name: logoZoomIn;
    animation-duration: 0.6s;
    animation-delay: 3.25s;

  }

  .splash-logo-stack {
    @extend %animated;
    animation-name: fadeIn, fadeOutLeft;
    animation-duration: 0.6s;
    animation-delay: 4.25s, 6s;
    animation-fill-mode: forwards;
  }

  .hero--main {
    @extend %animated;
    animation-name: splashSlideOut;
    animation-duration: 0.6s;
    animation-delay: 6s;
  }
}

.home.is-splash-animating-out {
  .branding .logo-outer {
    visibility: visible;
    opacity: 1;
    transform: rotate(-360deg) !important;
  }
}

}

.home.is-splash-complete {
  .splash {
    display: none !important;
  }
}

@include media-breakpoint-down(lg) {
  .splash {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none !important;
  }
}
