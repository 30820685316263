//
// Effects helpers
//

[data-hide-until="is-scrolled"],
[data-hide-until="is-scrolling"] {
  transition: $transition-base;
  opacity: 0;
}

.is-scrolled [data-hide-until="is-scrolled"] {
  opacity: 1;
}

.is-scrolling [data-hide-until="is-scrolling"] {
  opacity: 1;
}

[data-hide-if="is-scrolled"],
[data-hide-if="is-scrolling"],
[data-hide-if="is-footer-scrolled"],
[data-hide-if="is-footer-scrolling"] {
  transition: $transition-base;
  opacity: 1;
}

.is-scrolled [data-hide-if="is-scrolled"],
.is-footer-scrolled [data-hide-if="is-footer-scrolled"] {
  opacity: 0;
}

.is-scrolling [data-hide-if="is-scrolling"],
.is-footer-scrolling [data-hide-if="is-footer-scrolling"] {
  opacity: 0;
}


//
// Text helpers
//

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-family-serif {
  font-family: $font-family-serif;
}

.font-family-sans-serif {
  font-family: $font-family-sans-serif;
}

//
// Mobile image overlay helper
//

.is-overlay {
  @include media-breakpoint-down(lg) {
    img {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: 0.4;
      z-index: 2;
      transition: $transition-base;
    }

    .is-scrolling &::after {
      opacity: 0;
    }
  }
}

//
// Margin helpers
//

.mb-0 {
  margin-bottom: 0;
}

//
// Responsive helpers
//

.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-none {
  display: none;
}

@each $key, $val in $grid-breakpoints {

  @include media-breakpoint-up($key) {
    .d-block-#{$key} {
      display: block;
    }
    .d-inline-#{$key} {
      display: inline;
    }
    .d-inline-block-#{$key} {
      display: inline-block;
    }
    .d-none-#{$key} {
      display: none;
    }
  }
}

//
// Screenreaders
//

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}
