//
// colors
//
$black: #000;
$white: #fff;
$gray: #666666;
$gray-light: #707070;
$red: #E1001E;

//
// grid
//

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1460px;

$grid-breakpoints: (
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl
);

$grid-gutter-sm: 15px;
$grid-gutter-lg: 24px;

$container-gutter-sm: 15px;
$container-gutter-lg: 40px;

$container-max-width: 1200px;

$outer-gutter-sm: 30px;
$outer-gutter-lg: 64px;
$outer-gutter-xl: 90px;

$rail-width-lg: 47%;
$main-width-lg: 53%;

$rail-width-xl: 38.5%;
$main-width-xl: 61.5%;

//
// links
//
$link-color: $white;
$link-decoration: underline;
$link-hover-color: $white;
$link-hover-decoration: underline;

//
// typography
//
$body-bg: $black;
$body-color: $white;

$font-family-sans-serif: roboto-mono, monospace;
$font-family-display: oswald, sans-serif;
$font-family-serif: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;
$font-size-base-mobile: 1rem;
$font-size-base-rem: 1rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$font-weight-base: $font-weight-normal;
$line-height-base: (27/18);
$line-height-base-mobile: (30/20);

$text-muted: $gray;

//
// Borders
//
$border-color: $gray;
$border-radius: 5px;

//
// Spacing helpers - let's get rid of these
//

$space-5: 5px;
$space-10: 10px;
$space-15: 15px;
$space-20: 20px;
$space-25: 25px;
$space-30: 30px;
$space-35: 35px;
$space-40: 40px;
$space-45: 45px;
$space-50: 50px;
$space-55: 55px;
$space-60: 60px;
$space-90: 90px;
$space-180: 180px;

//
// Default transition
//

$transition-base: all 0.6s ease;

