

%animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  @media (print), (prefers-reduced-motion: reduce) {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

.animated {
  @extend %animated;
}


@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideBrandingInDown {
  from {
    transform: translate3d(0, -120%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideBrandingInCentered {
  from {
    top: calc(0 - 200px);
  }

  to {
    top: calc(50% - 97px);
  }
}

@keyframes rotateBranding {
  0% {
    transform: rotate(0deg);
  }

  99% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}


@keyframes slideInLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}
