
.taplist {
  @include list-reset();

  @include media-breakpoint-up(lg) {
    padding-right: 5%;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 8.25%;
  }

  h2 + & {
    margin-top: 14px;
  }

  .taplist-item {
    margin: 0 0 10px 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid $gray;

    display: flex;

    .btn {
      flex: 0 0 auto;
      align-self: center;
      margin-left: auto
    }
  }

  .taplist-body {
    padding-right: 10px;
  }

  .taplist-title {
    margin-bottom: 6px;
  }

  .taplist-type {
    margin-bottom: 5px;
  }

  .taplist-specs {
    span:not(:first-child)::before {
      content: "|";
      margin: 0 1em 0 0.5em;
    }
  }
}
