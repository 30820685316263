
.container {
	max-width: ($container-max-width + ($container-gutter-lg * 2));
	margin-left: auto;
	margin-right: auto;
  padding-left: $container-gutter-sm;
  padding-right: $container-gutter-sm;

  @include media-breakpoint-up(lg) {
    padding-left: $container-gutter-lg;
    padding-right: $container-gutter-lg;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  @include row-margins();
}

.grid__col {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;

  @include column-padding();
}

$bps: (
  'sm',
  'md',
  'lg',
  'xl'
);

@for $i from 2 through 4 {
  .grid--#{$i}up .grid__col {
    @include make-col(1, $i);
  }

  @for $i from 1 through 3 {
    .col-#{$i}of3 {
      @include make-col($i, 3);
    }
  }

  @for $i from 1 through 4 {
    .col-#{$i}of4 {
      @include make-col($i, 4);
    }
  }
}

@each $bp in $bps {

  @include media-breakpoint-up($bp) {

    @for $i from 2 through 4 {
      .grid--#{$i}up-#{$bp} .grid__col {
        @include make-col(1, $i);
      }
    }

    @for $i from 1 through 3 {
      .col-#{$i}of3-#{$bp} {
        @include make-col($i, 3);
      }
    }

    @for $i from 1 through 4 {
      .col-#{$i}of4-#{$bp} {
        @include make-col($i, 4);
      }
    }

  }

}

.grid--no-gutters {
  margin-left: 0;
  margin-right: 0;

  .grid__col {
    padding-left: 0;
    padding-right: 0;
  }
}
