html {
	font-size: $font-size-base-rem;
	box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
	font-family: $font-family-base;
  font-size: $font-size-base-mobile;
	font-weight: $font-weight-base;
	line-height: $line-height-base-mobile;
	color: $body-color;
  background: $body-bg;
	@include font-smoothing(on);

	@include media-breakpoint-up(lg) {
		font-size: $font-size-base;
    line-height: $line-height-base;
	}
}

main {
  flex: 1 0 auto;
  width: 100%;
  overflow-x: hidden;
}

strong, b {
	font-weight: $font-weight-bold;
}

a {
	color: $link-color;
	text-decoration: $link-decoration;
  transition: $transition-base;

	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
		outline: 0;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
.is-h1--style,
.is-h2--style,
.is-h3--style,
.is-h4--style,
.is-h5--style,
.is-h6--style {
	font-family: $font-family-display;
  font-weight: $font-weight-normal;
	margin: 0;
  text-transform: uppercase;
}

h1, .is-h1--style,
h2, .is-h2--style {
 //  font-family: $font-family-display;
	// font-weight: $font-weight-normal;
}

h1, .is-h1--style {
	font-size: rem(48);
	line-height: 1;
  margin-bottom: 25px;

  letter-spacing: 0.035em;

	@include media-breakpoint-up(lg) {
		font-size: rem(60);
		line-height: 1;
		margin-bottom: 30px;
    margin-left: -3px;
	}

  @include media-breakpoint-up(xl) {
    font-size: rem(62);
  }
}

.is-h1--jumbo {
  @include media-breakpoint-up(xl) {
    font-size: rem(68);
  }
}

.is-h1--top {
  margin-bottom: 20px;
}

h2, .is-h2--style {
	font-size: rem(31);
	line-height: 1;
  letter-spacing: 0;
	margin-bottom: ($space-25 + 2px);

	@include media-breakpoint-up(lg) {
		line-height: (44/36);
		margin-bottom: $space-30;
	}
}

h3, .is-h3--style {
  letter-spacing: 0.01em;
	font-size: rem(20);
	line-height: (32/24);
  margin-bottom: $space-15;
}

h4, .is-h4--style {
	font-size: rem(16);
	line-height: (27/18);
  margin-bottom: 1px;
}

.is-eyebrow {
  text-transform: uppercase;
  letter-spacing: 0.10em;
}

.is-condensed{
  letter-spacing: 0;
}

.is-muted {
  color: $gray;
}

.is-text-max {
  @include media-breakpoint-up(lg) {
    padding-right: 5%;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 19%;
  }
}

a.is-muted {
  color: $gray;
  text-decoration: none;

  &:hover, &:active {
    color: $white;
  }
}

.more-link {
  font-family: $font-family-display;
  color: $gray;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 36px;

  &:hover, &:active {
    color: $white;
    text-decoration: none;
  }
}

p, ol, ul, dl {
	margin: 0 0 1.5em 0;
}

nav ul {
  @include list-reset();
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
  padding: 0;

  img {
    display: block;
  }

  figcaption {
    margin-top: $space-10;
    font-size: rem(16);
    line-height: (20/16);
  }
}
