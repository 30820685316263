
@mixin make-col($size, $columns: 12) {
	flex: 0 0 auto;
	width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: 12) {
  margin-left: percentage($size / $columns);
}

@mixin container-padding() {
  padding-right: $container-gutter-sm / 2;
  padding-left:  $container-gutter-sm / 2;

  @include media-breakpoint-up(lg) {
    padding-right: $container-gutter-lg / 2;
    padding-left:  $container-gutter-lg / 2;
  }
}

@mixin row-margins() {
  margin-right: -$grid-gutter-sm / 2;
  margin-left:  -$grid-gutter-sm / 2;

  @include media-breakpoint-up(lg) {
    margin-right: -$grid-gutter-lg / 2;
    margin-left:  -$grid-gutter-lg / 2;
  }
}

@mixin column-padding() {
  padding-right: $grid-gutter-sm / 2;
  padding-left:  $grid-gutter-sm / 2;

  @include media-breakpoint-up(lg) {
    padding-right: $grid-gutter-lg / 2;
    padding-left:  $grid-gutter-lg / 2;
  }
}
