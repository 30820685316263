
.swatch {
	font-size: 14px;
	font-family: $font-family-sans-serif;
	line-height: 1.2;
	padding: 10px;
	min-height: 90px;

  &:not([class*="text-"]) {
    color: $white;
  }

	.grid--no-gutters & {
		padding: 10px;
	}

	strong {
		display: block;
	}
}

.sg-icons {
	svg {
		display: inline-block;
		margin: $space-15 $space-5;
	}
}

$sg-pink: #ec008c;

.debug {
  border: 1px solid $sg-pink;
}

.is-note {
	color: $sg-pink;
}

.label.is-note {
	color: $white;
	background: $sg-pink;
}

body.debug-breakpoints {

	&::after {
		content: "mobile";
		background: $sg-pink;
		color: $white;
		display: block;
		padding: 5px 10px;
		position: fixed;
		bottom: 0;
		right: 0;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: 1px;
    z-index: 999999;

		@include media-breakpoint-up(sm) {
			content: "small";
		}

		@include media-breakpoint-up(md) {
			content: "medium";
		}

		@include media-breakpoint-up(lg) {
			content: "large";
		}

		@include media-breakpoint-up(xl) {
			content: "x-large";
		}
	}
}
