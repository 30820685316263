.icon path {
  fill: currentColor;
}

ul.social {
  display: flex;
  flex-direction: row;
  align-items: center;

  li {
    margin: 3px 5px 0 0;
  }
}
