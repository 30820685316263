//
// Tabs
//

.tabs {
  .tabs__nav {
    overflow-x: auto;
    @include media-breakpoint-up(lg) {
      padding-right: 5%;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 8.25%;
    }

    // @hack hidden scrollbars
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      background: transparent;
    }
    // END @hack
  }

  .tabs__tablist {
    display: flex;
    border-bottom: 1px solid $gray;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
      }

    li {
      margin-bottom: -1px;
      margin-right: 20px;

      @include media-breakpoint-up(lg) {
        margin-right: 35px;
      }
    }

    a {
      color: $white;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      display: inline-block;
      white-space: nowrap;
      margin: 0;
      padding-bottom: 15px;
    }

    a:hover,
    a:active {
      color: $white;
    }

    a[aria-selected="true"] {
      color: $white;
      border-color: $white;
      border-width: 3px;
      padding-bottom: 14px;
      margin-bottom: -1px;
    }
  }

  .tabs__pane {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;

    &.show {
      height: auto;
      opacity: 1;
    }
  }
}
