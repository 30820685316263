.hero {
  // height: 100vh;
  // width: 62%;
  position: relative;
  // top: 0;
  // right: 0;
  // z-index: -1;
  //min-height: 412px;

  @include media-breakpoint-up(lg) {
    height: 100vh;
    width: $main-width-lg;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  @include media-breakpoint-up(xl) {
    width: $main-width-xl;
  }

  img {
    object-fit: cover;
    object-position: 50% 50%;
    font-family: 'object-fit: cover; object-position: 50% 50%;';
    width: 100%;
    height: 100%;
    display: block;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    // responsive manual line breaks
    br {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero.is-fixed {
    position: fixed;
  }


  .hero--rail {
    width: $rail-width-lg;
    right: auto;
    left: 0;
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

@include media-breakpoint-up(xl) {
  .hero--rail {
    width: $rail-width-xl;
  }
}
